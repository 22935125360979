import React from "react"
import Base from "../components/base"
import Teasers from "../components/teasers"
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/Seo";

const Archiv  =  ({pageContext}) => {
  const data = useStaticQuery(graphql`
      {
          site {
              siteMetadata {
                  ...SiteMeta
              }
          }
          teasers: allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}) {
              edges {
                  node {
                      fields {
                          slug
                      }
                      id
                      frontmatter {
                          ...FrontMatter
                      }
                      html
                      image {
                          childImageSharp {
                              fluid (quality: 90, maxWidth: 400) {
                                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                              }
                          }
                      }
                  }
              }
          }
      }
  `);

  return (<Base>
      <Seo article image={data.site.siteMetadata.defaultImage}
           description={data.site.siteMetadata.description}
           title="Archiv"
           pathname={pageContext.slug}/>
      <Teasers withImg={false} headline="~ Archiv ~" teasers={data.teasers.edges}/>
  </Base>)
}
export default Archiv;